.dropdown-menu {
    width: auto;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
}

.dropdown-menu li {
    /* background: #000; */
    cursor: pointer;
    color: #000;
}

/* .dropdown-menu li:hover {
    background: #333;
} */

.drop-menu {
    display: none;
}

.menu-items {
    display: grid;
    text-decoration: none;
    color: #000;
    padding: 15px;
}

@media screen and (max-width: 1000px) {
    .dropdown-menu {
        width: auto;
        position: relative;
        top: 20px;
        list-style: none;
        text-align: start;
    }
}