.contact{
    padding: 150px 260px;
}


.contact-line{
    width: 250px;
    height: 1px;
    background-color: #000;
}

.contact-text{
    padding: 40px 0;
}
.contact-text h1{
    font-size: 40px;
    font-weight: 600;
}

.contact_section{
    background: url(../Imges/conract.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* this is is contact box section css  */

.contact_box{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.con_btn button{
    /* margin-top: 70px; */
    width: 200px;
    height: 40px;
    margin-top: 45px;
    background-color: transparent;
    color: #000;
    font-size: 18px;
    letter-spacing: 2px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 2px solid #000;
    transition: all 0.3s ease-in-out;
}

.con_btn button:hover{
    background-color: #000;
    color: #fff;
}

.con_areya{
    width: 100%;
    height: 500px;
    background-color: #000;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    color: #fff;
}

.text_con{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.con_areya_text2 h2{
    font-size: 12px;
}


/* responsive code  */

@media (max-width: 750px){
    .contact{
        padding: 150px 40px;
    }
    .con_areya{
        width: 100%;
        height: 100%;
    }
    .text_con{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .con_areya_text2{
        padding: 20px 30px;
    }
    
}