.policy{
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 50px 30px;
}
.categorypurpose {
    display: flex;
    justify-content: space-around;
    padding: 10px 50px;
}

/* responsive code  */

@media (max-width: 750px){
    .category {
        display: flex;
        flex-direction: column;
    }
}