.investment{
    background-image: url(../Imges/invest.jpg);
    width: 100%;
    /* height: 100vh; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.invest_heading{
    display: flex;
    width: 100%;
    justify-content: center;
}
.invest_heading h2{
    text-align: center;
}


.invest_head{
    padding: 30px 0;
}

.invest_head P{
    text-align: center;
}

.invest_head h3{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 30px 150px;
}

.invest_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 200px;
}
.item_left{
    width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item_right {
    width: 50%;
    height: 200px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.item_right p{
    text-align: center;
}

@media (max-width: 600px){
    .invest_head h3{
        padding: 30px 10px;
    }
    .invest_item{
        display: flex;
        flex-direction: column;
        padding: 50px 10px;
    }
    .item_right {
        width: 98%;
        height: 250px;
    }
    .item_left {
        width: 98%;
        height: 250px;
    }
}