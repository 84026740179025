
.bottom {
    width: 80%;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: 0;
  }
  
  .empty {
    background-color: rgb(37, 37, 37);
    width: 70%;
    left: 0;
    height: 1rem;
    position: relative;
    margin: 0 auto;
    padding: 0;
  }
  
  .start > a {
    right: 0;
    bottom: 0;
    position: absolute;
    padding: 2.0rem 3.5rem;
    font-size: 1rem;
    background-color: rgb(252, 252, 252);
    width: fit-content;
    text-decoration: none;
    color: #000;
    border-top: 1px solid #000;
    font-weight: 400;
    transition: 0.8s;
  }
  
  .start > a:hover{
    background-color: #000;
    color: #fff;
  }
  
  