.home{
    padding: 50px 270px;
}

.home_line{
    width: 250px;
    height: 1px;
    background-color: #000;
}
.home_text{
    padding: 50px 0;
}
.home_text h1{
    font-size: 60px;
    font-weight: 600;
    color: #000;
}
.home_section{
    background: url(../Imges/bg2.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



/* responsive code  */

@media (max-width: 750px){
    .home{
        padding: 10px 70px;
    }
    .home_text h1{
        font-size: 40px;
        font-weight: 600;
        color: #000;
    }
    .home_section{
        width: 100%;
        height: 100vh;
    }
}