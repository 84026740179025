.tetexdt {
    width: 100%;
    padding: 20px 200px;
    background-color: #000;
    color: #fff;
}

.tetexdt a{
    text-decoration: none;
    color: #ffff;
    font-size: 30px;
}


@media (max-width: 500px){
    .tetexdt {
        padding: 10px 20px;
    }
}


#AB&T{
    width: 100%;
    height: 100vh;
    background-color: #000;
    color: #fff;
}