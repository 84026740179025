/* .login_section{
    background: url(../Imges/login.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

.heading{
    text-align: center;
}