.disclaimer{
    width: 100%;
    height: auto;
    background-color: #000;
    color: #fff;
    text-align: center;
    position: absolute;
}

.dis_btn{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.dis_btn button{
    color: #000;
    cursor: pointer;
}

