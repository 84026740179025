.team_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box_card{
    width: 350px;
    height: 400px;
    background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.18);
    box-shadow: 0 8px 32px rgba(0,0,0,0.37);
    border-radius: 20px;
    margin: 20px;
    overflow: hidden;
}

.box_card img{
    width: 350px;
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: all 0.3s ease-in-out;
}

.box_card img:hover{
    transform: scale(1.2);
}

.box_body{
    padding: 30px 10px;
}
.box_body a{
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

.team_headibg h1{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.team_continer{
    padding: 80px 0;
    display: flex;
}

.team_section{
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* responsive code  */

@media (max-width: 750px){
    .team_continer {
        display: flex;
        flex-direction: column;
    }
    .box_card{
        width: 330px;
        height: 400px;
    }
    .team_headibg h1{
        font-size: 15px;
        text-align: center;
    }
}