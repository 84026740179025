.fp-iframe{
    width: 100%;
    height: 80vh;
    border: 1px solid lightgray; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.news_box{
    /* width: 100%; */
    height: 800px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news_heading{
    text-align: center;
}