.curenteinvestment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Cur_heading h2{
    text-align: center;
    color: #1c67a5;
}
.cur_box{
    width: 100%;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.box_1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 400px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 20px;
    margin: 20px;
}

.box_img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box_img img{
    width: 200px;
    height: 150px;
}
.box_text {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.box_text a{
    text-decoration: none;
}
.box_2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 400px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 20px;
    margin: 20px;
}
.box_text2 {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
}
.box_text2 a{
    text-decoration: none;
}
.box_img2{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.box_img2 img{
    width: 200px;
    height: 150px;

}



@media (max-width: 600px) {
    .box_1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 98%;
        height: 600px;
    }
    .box_img {
        width: 100%;
    }
    .box_text {
        width: 100%;
        padding: 0px 20px;
    }

    .box_2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 98%;
        height: 600px;
    }
    .box_text2 {
        width: 100%;
        padding: 0 20px;
    }
    
}