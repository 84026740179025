.team{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    flex-direction: column;
}

.team p{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 0 100px;
}

.dot{
    display: flex;
    padding: 0 200px;
    flex-direction: column;
}
.dot span{
    font-size: 14px;
    font-weight: 400;
}


/* responsive code  */

@media (max-width: 750px){
    .team h1{
        font-size: 22px;
        margin-top: 30px;
    }
    .team p{
        padding: 0 20px;
    }
    .dot{
        display: flex;
        padding: 0 20px;
        flex-direction: column;
    }
    .team span{
        text-align: center;
    }
}