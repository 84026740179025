.about {
  padding: 150px 300px;
}

.about_line {
  width: 250px;
  height: 1px;
  background-color: #000;
}
.about_text {
  padding: 50px 0;
}
.about_text h1 {
  font-size: 40px;
}

.about_section {
  background: url(../Imges/bg11.jpg);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* overflow: hidden; */
}

/*  */

.one {
  display: flex;
  scroll-snap-align: start;
  background-color: #000;
  color: #fff;
  flex-direction: column;
}

.about_container {
  padding: 0px 0;
}

.btn button {
  width: 200px;
  height: 40px;
  margin-top: 30px;
  background-color: transparent;
  color: #000;
  font-size: 18px;
  letter-spacing: 2px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 2px solid #000;
  transition: all 0.3s ease-in-out;
}

.btn button:hover {
  background-color: #000;
  color: #fff;
}

.one_heading {
  padding: 50px 300px;
}

.one_text {
  padding: 50px 300px;
  /* width: 500px; */
}

.square {
  height: 600px;
  background-color: #0a0909;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.square_one {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.zero {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 4px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 50px; */
}

.zero h1 {
  font-size: 50px;
}
.zero_text h2 {
  font-size: 35px;
}

.square_two {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* responsive code  */

@media (max-width: 750px) {
  .about {
    padding: 10px 70px;
  }
  .about_text h1 {
    font-size: 40px;
  }
  .one_text {
    padding: 50px 30px;
  }
  .square_one {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .zero {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 4px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 50px; */
  }
  .zero_text h2 {
    font-size: 25px;
    margin-top: 30px;
  }
  .square_two {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .one_heading {
    padding: 50px 25px;
  }
}
