.preloader{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    color: #000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: flex;
    justify-content: center;
    align-items: center;
}

.texts-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.texts-container .icon{
    color: #ccc;
    font-size: 50px;
    text-align: center;
}

.text{
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 4px;
    text-align: center;
}

.line{
    width: 200px;
    height: 1px;
    background-color: #000;
    margin-top: 20px;
}

.texts-container img{
    width: 150px;
    height: 200px;
}

.texts-container img{
    width: 400px;
    height: 300px;
    margin-top: 200px;
}