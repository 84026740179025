.relizedinvestment{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.relized_heading h2{
    text-align: center;
    color: #1c67a5;
}

.box_img{
    display: flex;
    flex-direction: column;
}