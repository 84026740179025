.show{
    width: 100%;
    height: 100vh;
}
.showlog{
    display: flex;
    justify-content: space-around;
}

.showlog button{
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.showlog h1{
    font-size: 18px;
}
.pdf{
    padding: 50px 300px;
    display: flex;
    flex-direction: column;
}

.pdf a {
    margin-left: 60px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    margin-top: 10px;
}

.pdf h2{
    margin-top: 30px;
}


@media (max-width: 676px){
    .pdf{
        padding: 2px 5px;
    }
    .pdf a{
        margin-left: 5px;
    }
    .pdf h2{
        font-size: 18px;
    }
}