.login_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

form{
    width: 450px;
    height: 500px;
    background-image: linear-gradient(#36D1DC, #5B86E5);
    background: -webkit-linear-gradient(to right, #36D1DC, #5B86E5);
    background: linear-gradient(to right, #36D1DC, #5B86E5);
    border-radius: 20px;
    color: #fff;
}

.login_heading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;
}

.login_heading span{
    margin-top: 20px;
    border-bottom: 2px solid red;
    color: red;
}
.inp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    margin-top: -40px;
}
input{
    padding: 10px 50px;
    border: none;
    outline: none;
    border-radius: 10px;
    margin-top: 20px;
}
.inp button{
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 10px;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
}

.btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_section{
    background: url(../../Imges/login.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.log{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.log h1{
    font-size: 16px;
}
.log button{
    background: none;
    border: none;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}


/* responsive code  */

@media (max-width: 750px){
    form{
        width: 350px;
        height: 500px
    }
}