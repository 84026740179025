.MainFooter{
    width: 100%;
    height: 100px;
    background-color: #181313;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.MainFooter_text p{
    font-size: 14px;
    font-weight: 500;
}

.item_link a{
    margin: 15px;
    text-decoration: none;
    color: #fff;
}


/* responsive code  */

@media (max-width: 750px){
    .MainFooter{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 150px;
    }
    .MainFooter_text p{
        font-size: 12px;
        font-weight: 500;
    }
    .item_link a{
        margin: 10px;
        text-decoration: none;
        color: #fff;
    }
    .item_link{
        margin-top: 40px;
    }
}